import { createElement } from "./Utils";
export class FabasoftGallery {
    constructor(galleryWrapper) {
        this.loadedImages = 0;
        this.defaultRowHeight = 130;
        this.borderWidth = 2;
        this.elementsInARow = [];
        this.rowWidth = 0;
        this.galleryWrapper = galleryWrapper;
        this.fabasoftId = this.galleryWrapper.getAttribute('data-fabasoft-root-id');
        this.galleryWidth = this.galleryWrapper.clientWidth - this.borderWidth;
        this.remainingWidth = this.galleryWidth;
        this.prepareHTMLSetup();
        this.loadFabasoftData();
    }
    prepareHTMLSetup() {
        let fragment = document.createDocumentFragment();
        this.galleryLoader = createElement('div', ['gallery__loader', 'obb-icon-loading']);
        this.gallery = createElement('div', ['gallery__inner']);
        fragment.appendChild(this.galleryLoader);
        fragment.appendChild(this.gallery);
        this.galleryWrapper.appendChild(fragment);
    }
    loadFabasoftData() {
        let that = this;
        if (typeof fsc != 'undefined' && typeof fsc.webcontent != 'undefined') {
            var fscwebcontentCalldata = this.getFscwebcontentCalldata(this.fabasoftId);
            fsc.webcontent.setSuccessCallback(function successcallback(data) {
                that.setupFabasoftGallery(data);
            });
            fsc.webcontent.setErrorCallback(function errorcallback(data) {
                if (data && data.errors) {
                    console.log(data.errors.apietext);
                }
            });
            fsc.webcontent.render(fscwebcontentCalldata);
        }
        else {
            this.loadFabasoftData();
        }
    }
    getFscwebcontentCalldata(fabasoftId) {
        return {
            target: "https://at.cloud.fabasoft.com/folio",
            rootobject: fabasoftId,
            sortby: ["COOSYSTEM_1_1_objname:UP:"],
            additionalcolumns: [
                "FSCTERM_1_1001_objterms",
                "FSCDIGITALASSETMGMT_1_1001_daaltname",
                "FSCDIGITALASSETMGMT_1_1001_daliccopyright",
                "FSCDIGITALASSETMGMT_1_1001_dadescription"
            ],
            maxleveldepth: 5,
            language: "en",
            locale: "en-US",
            timezoneoffset: -120,
            datetimeformat: "dd/mm/yy hh:mm",
            containerid: "FscJSLibContainer0",
            templateid: "not required",
            strings: ["FSCVENV_1_1001_StrExpand", "FSCVENV_1_1001_StrCollapse", "FSCSCM_1_1001_StrType"],
            dataonly: true //
        };
    }
    setupFabasoftGallery(data) {
        const defaultDescription = data.dadescription && data.dadescription.value ? data.dadescription.value : "", defaultCopyright = data.daliccopyright && data.daliccopyright.value ? data.daliccopyright.value : "";
        let fragment = document.createDocumentFragment();
        data.children_level1.forEach((element) => {
            let elementDescription = element.dadescription && element.dadescription.value ? element.dadescription.value : defaultDescription, elementCopyright = '© ' + (element.daliccopyright && element.daliccopyright.value ? element.daliccopyright.value : defaultCopyright);
            let aElement = createElement('a', ['gallery__item']);
            aElement.href = element.largepreviewurl.value;
            aElement.setAttribute('data-download-url', element.downloadurl.value);
            aElement.setAttribute('data-sub-html', '<span class="item__subnote">' + elementDescription + '</span>' +
                '<span class="item__subnote">' + elementCopyright + '</span>');
            let imgElement = createElement('img', ['gallery__thumb', 'thumb']);
            imgElement.src = element.thumbnailurl.value;
            imgElement.alt = elementDescription;
            imgElement.title = elementCopyright;
            imgElement.addEventListener('load', () => {
                this.loadedImages++;
                this.waitForThumbnails();
            });
            let posterElement = createElement('div', ['thumb__poster', 'obb-icon-zoom']);
            let captionElement = createElement('div', ['thumb__caption']);
            captionElement.innerHTML = elementDescription;
            aElement.appendChild(imgElement);
            aElement.appendChild(posterElement);
            aElement.appendChild(captionElement);
            fragment.appendChild(aElement);
        });
        this.gallery.appendChild(fragment);
        this.thumbnails = this.gallery.getElementsByClassName('gallery__thumb');
    }
    waitForThumbnails() {
        if (this.loadedImages == this.thumbnails.length) {
            this.setupImgSizes();
            this.createLightGallery();
            let nextGalleryIndex = parseInt(this.galleryWrapper.getAttribute('data-next-gallery-index'));
            if (nextGalleryIndex) {
                let nextGallery = document.querySelectorAll('.gallery--fabasoft')[nextGalleryIndex];
                new FabasoftGallery(nextGallery);
            }
        }
    }
    setupImgSizes() {
        for (let i = 0; i < this.thumbnails.length; i++) {
            let thumbnail = this.thumbnails[i];
            let aspectRatio = thumbnail.width / thumbnail.height;
            let calculatedWidth = Math.round(aspectRatio * this.defaultRowHeight);
            if (this.remainingWidth >= calculatedWidth) {
                thumbnail.style.width = calculatedWidth + "px";
                thumbnail.style.height = this.defaultRowHeight + "px";
                this.elementsInARow.push(i);
                this.rowWidth += calculatedWidth;
                this.remainingWidth -= calculatedWidth - this.borderWidth;
            }
            else {
                if (this.remainingWidth < 150) {
                    this.recalculateImagesInARow();
                    thumbnail.style.width = calculatedWidth + "px";
                    thumbnail.style.height = this.defaultRowHeight + "px";
                    this.elementsInARow.push(i);
                    this.rowWidth += calculatedWidth;
                    this.remainingWidth = this.galleryWidth - calculatedWidth - this.borderWidth;
                }
                else {
                    thumbnail.style.width = calculatedWidth + "px";
                    thumbnail.style.height = this.defaultRowHeight + "px";
                    this.elementsInARow.push(i);
                    this.rowWidth += calculatedWidth;
                    this.remainingWidth = this.galleryWidth;
                    this.recalculateImagesInARow();
                }
            }
        }
        this.galleryWrapper.classList.add('gallery--ready');
    }
    recalculateImagesInARow() {
        let borderPx = this.elementsInARow.length * this.borderWidth;
        let rowRatio = (this.galleryWidth - borderPx) / this.rowWidth;
        this.elementsInARow.forEach(element => {
            let thumbnail = this.thumbnails[element];
            thumbnail.style.width = Math.round(thumbnail.width * rowRatio) + "px";
            thumbnail.style.height = thumbnail.height * rowRatio + "px";
        });
        this.elementsInARow = [];
        this.rowWidth = 0;
    }
    createLightGallery() {
        if (!document.querySelector('.js-lightgallery-script')) {
            let scriptElement = createElement('script', ['js-lightgallery-script']);
            scriptElement.async = true;
            scriptElement.defer = true;
            scriptElement.src = '/.resources/rcg-2019/themes/js/vendor/lightgallery.min.js?refresh=-1';
            scriptElement.addEventListener('load', () => {
                lightGallery(this.gallery);
            });
            document.body.appendChild(scriptElement);
        }
        else {
            lightGallery(this.gallery);
        }
    }
    static addStyleAndScript(onLoadCallback) {
        if (!document.querySelector('.js-lightgallery-style')) {
            let linkElement = createElement('link', ['js-lightgallery-style']);
            linkElement.rel = 'stylesheet';
            linkElement.href = '/.resources/rcg-2019/themes/css/vendor/lightgallery/css/lightgallery.css';
            document.head.appendChild(linkElement);
        }
        if (!document.querySelector('.js-gallery-script')) {
            let scriptElement = createElement('script', ['js-gallery-script']);
            scriptElement.async = true;
            scriptElement.defer = true;
            scriptElement.src = 'https://at.cloud.fabasoft.com/folio/fscasp/content/tmp/FSCVENVUI_1_1001/fsc.webcontent.js';
            scriptElement.addEventListener('load', function () {
                onLoadCallback();
            });
            document.body.appendChild(scriptElement);
        }
    }
}
export function initFabasoftGallery() {
    const galleries = document.querySelectorAll('.gallery--fabasoft');
    if (galleries.length > 0) {
        FabasoftGallery.addStyleAndScript(function () {
            for (let i = 0; i < galleries.length; i++) {
                if (galleries[i + 1]) {
                    galleries[i].setAttribute('data-next-gallery-index', (i + 1) + "");
                }
                if (i == 0) {
                    new FabasoftGallery(galleries[i]);
                }
            }
        });
    }
}
